import "./App.css";

import Collection from "./Collection/Collection";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AppProvider, EmptyState, Page } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";

function App() {
  return (
    <AppProvider i18n={translations}>
      <Router>
        <Switch>
          <Route path="/collection" component={Collection} />
        </Switch>
      </Router>
    </AppProvider>
  );
}

export default App;
