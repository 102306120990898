import React, { useEffect, useState } from "react";
import "./CampSlider.css";
import BtnSlider from "./BtnSlider";
import ReactPlayer from "react-player";
import { playIcon } from "../../../assets";

export default function CampSlider(props) {
  const [slideIndex, setSlideIndex] = useState(1);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const nextSlide = () => {
    if (slideIndex !== props.media.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === props.media.length) {
      setSlideIndex(1);
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsVideoPlaying(true);
  //     console.log("setIsVideoPlaying Called " + isVideoPlaying);
  //   }, 5000);

  //   return () => {};
  // }, []);

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(props.media.length);
    }
  };

  const moveDot = (index) => {
    setSlideIndex(index);
  };

  return (
    <div className="container-slider">
      {props.media.map((obj, index) => {
        return (
          <div
            key={obj.id}
            style={{ height: "100%" }}
            className={slideIndex === index + 1 ? "active-anim" : "slide"}
          >
            <div className="camp_cont__cpgc">
              {obj.viewable_media_type == "VIDEO" ? (
                <ReactPlayer
                  style={{ position: "absolute", top: 0, left: 0 }}
                  className="slider-react-player"
                  controls={true}
                  light={obj.thumbnail_url}
                  url={obj.media_url}
                  playIcon={<img src={playIcon} height={50} width={50} />}
                  width="100%"
                  height="100%"
                  playing={true}
                  onReady={() => {
                    // handleOnReadyState(i, obj.id);
                  }}
                  onPause={() => {
                    // handlePauseState(i, obj.id);
                  }}
                  onPlay={() => {
                    // handlePlayState(i, obj.id);
                  }}
                  onClickPreview={() => {}}
                  config={{
                    file: {
                      attributes: {
                        style: {
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        },
                      },
                    },
                  }}
                />
              ) : (
                <img
                  alt=""
                  className="slider-pc-body-image-new"
                  height="100%"
                  src={obj.media_url}
                />
              )}
            </div>
          </div>
        );
      })}
      <BtnSlider moveSlide={nextSlide} direction={"next"} />
      <BtnSlider moveSlide={prevSlide} direction={"prev"} />
    </div>
  );
}
