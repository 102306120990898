import React from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import "./CollectionPostGridCard.css";
import { Badge } from "@shopify/polaris";

import {
  reelPostIcon,
  feedPostIcon,
  storyPostIcon,
  playIcon,
} from "../../assets";
import ReactPlayer from "react-player";
import moment from "moment";
import CampSlider from "./widgets/CampSlider";

import { Box, ButtonBase } from "@mui/material";
import UserProfile from "../../assets/user.png";
import DownloadIcon from "../../assets/downloadIcon.svg";

const ReactGridLayout = WidthProvider(RGL);

export default class CollectionPostGridCard extends React.PureComponent {
  addPostMenuHandler() {}

  delta = (indexValue) => {
    this.setState({
      addClick: !this.state.addClick,
      postKey: (this.state.postKey = indexValue),
    });
  };

  static defaultProps = {
    className: "layout",
    isDraggable: false,
    isResizable: false,
    cols: 12,
    rowHeight: 30,
    onLayoutChange: function () {},
  };

  constructor(props) {
    super(props);

    const layout = this.generateLayout();
    this.state = {
      layout,
      contentData: props.contentData,
      itemWidth: props.itemWidth,
      downloadMediaAPI: props.downloadMediaAPI,
      currentScreenSizes: props.currentScreenSizes,
    };
  }

  handlePauseState = (index, post_id) => {
    const newPosts = this.state.contentData.map((post) =>
      post.id === post_id ? { ...post, is_video_playing: false } : { ...post }
    );

    this.setState({
      contentData: newPosts,
    });
  };

  handlePlayState = (index, post_id) => {
    const newPosts = this.state.contentData.map((post) =>
      post.id === post_id
        ? { ...post, is_video_playing: true }
        : { ...post, is_video_playing: false }
    );

    this.setState({
      contentData: newPosts,
    });
  };

  handleOnReadyState = (index, post_id) => {
    const newPosts = this.state.contentData.map((post) =>
      post.id === post_id
        ? { ...post, show_usage_rights: false, is_video_playing: true }
        : { ...post, is_video_playing: false }
    );

    this.setState({
      contentData: newPosts,
    });
  };

  // replace image function
  replaceImage = (error) => {
    //replacement of broken Image
    error.target.src = UserProfile;
  };

  generateDOM(
    contentData,
    itemWidth,
    downloadMediaAPI,
    handlePauseState,
    handlePlayState,
    handleOnReadyState,
    replaceImage
  ) {
    return _.map(_.range(this.props.contentData.length), function (i) {
      return (
        // <CollectionPostGridCard setKey={i} />
        <div key={i} className="main-pc">
          <Box className="card_header_cpgc">
            {/* Post Card Header */}
            <div className="pc-header-inside container-set">
              <Box position="relative">
                <Box display="flex">
                  <img
                    className="profile_image_cpgc"
                    src={contentData[i].creator.display}
                    size="small"
                    onError={(e) => replaceImage(e)}
                    width={34}
                    height={34}
                  />
                  <Box mt={0.4} ml={1}>
                    <Box className="profile_username__cpgc">
                      {contentData[i].creator.username}
                    </Box>
                    <Box className="profile_followers__cpgc">
                      {contentData[i].creator?.followers > 1000
                        ? Math.round(
                            (contentData[i].creator?.followers / 1000) * 10
                          ) /
                            10 +
                          "k followers"
                        : contentData[i].creator?.followers != null &&
                          contentData[i].creator?.followers != 0
                        ? `${contentData[i].creator?.followers} followers`
                        : ""}
                    </Box>
                  </Box>
                </Box>
                <Box className="post_icon_on_profile__cpgc">
                  <img
                    src={
                      contentData[i].post_type === "REELS" ||
                      contentData[i].post_type === "IMAGE"
                        ? reelPostIcon
                        : contentData[i].post_type === "STORY"
                        ? storyPostIcon
                        : feedPostIcon
                    }
                    width={14}
                    height={14}
                  />
                </Box>
              </Box>
            </div>
          </Box>

          <Box className="download_content_btn__cpgc">
            <ButtonBase
              sx={{ borderRadius: "50%" }}
              onClick={() => {
                downloadMediaAPI(contentData[i].id, contentData[i]);
              }}
            >
              <img src={DownloadIcon} width={30} height={30} />
            </ButtonBase>
          </Box>

          <Box className="rights_badges_box">
            {contentData[i].show_usage_rights ? (
              <Box className="usage_rights__pgc">
                {contentData[i].rights_status == "GRANTED" ? (
                  <Badge size="small" tone="success">
                    Approved for Usage
                  </Badge>
                ) : null}

                {contentData[i].rights_status == "DENIED" ? (
                  <Badge size="small" tone="critical">
                    Usage rights declined
                  </Badge>
                ) : null}
              </Box>
            ) : null}
          </Box>
          <Box className="post_time__cpgc">
            {moment(contentData[i].created_at).format("MMM DD")}
          </Box>

          <Box className="top_gradiant_overlay_cpgc">{/*  Top Gradiant */}</Box>

          {contentData[i].show_usage_rights != false ? (
            <Box className="bottom_gradiant_overlay_cpgc"></Box>
          ) : null}

          <div className="camp_cont__cpgc">
            {contentData[i].media_type == "VIDEO" ? (
              // <div
              //   style={{
              //     position: "relative",
              //     paddingTop: "100%",
              //     height: "100%",
              //   }}
              // >
              <ReactPlayer
                style={{ position: "absolute", top: 0, left: 0 }}
                className="react-player"
                controls={true}
                light={contentData[i]?.media[0]?.thumbnail_url}
                playIcon={<img src={playIcon} height={50} width={50} />}
                url={contentData[i]?.media[0]?.media_url}
                width="100%"
                height="100%"
                playing={contentData[i]?.is_video_playing}
                onReady={() => {
                  handleOnReadyState(i, contentData[i]?.id);
                }}
                onPause={() => {
                  handlePauseState(i, contentData[i]?.id);
                }}
                onPlay={() => {
                  handlePlayState(i, contentData[i]?.id);
                }}
                onClickPreview={() => {}}
                config={{
                  file: {
                    attributes: {
                      style: {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      },
                    },
                  },
                }}
              />
            ) : // </div>
            contentData[i].media_type == "IMAGE" ? (
              <img
                alt=""
                className="pc-body-image"
                height="100%"
                src={contentData[i]?.media[0]?.media_url}
              />
            ) : (
              <CampSlider media={contentData[i].media} />
            )}
          </div>

          {/* Post Card Footer */}
          <div className="pc-footer-old container-set"></div>
        </div>
      );
    });
  }

  generateLayout() {
    const p = this.props;
    return _.map(new Array(p.contentData.length), function (item, i) {
      var itemHeight = 0;

      var setHeightValue1 = 0.0;
      var setHeightValue2 = 0.0;
      //---- Checking Screen Sizes ------
      if (p.currentScreenSizes.screenWidth >= 810) {
        console.log("check810x1080");
        setHeightValue1 = 3.7;
        setHeightValue2 = 2.2;
      }

      if (p.currentScreenSizes.screenWidth >= 1080) {
        console.log("check1280x1024");
        setHeightValue1 = 4.0;
        setHeightValue2 = 2.5;
      }

      if (p.currentScreenSizes.screenWidth >= 1280) {
        console.log("check1280x1024");
        setHeightValue1 = 5.0;
        setHeightValue2 = 2.9;
      }

      if (p.currentScreenSizes.screenWidth >= 1366) {
        console.log("check1366x786");
        setHeightValue1 = 5.0;
        setHeightValue2 = 3.0;
      }
      if (p.currentScreenSizes.screenWidth >= 1440) {
        console.log("check1440x900");
        setHeightValue1 = 5.3;
        setHeightValue2 = 3.2;
      }
      if (p.currentScreenSizes.screenWidth >= 1536) {
        console.log("check1536x864");
        setHeightValue1 = 5.7;
        setHeightValue2 = 3.3;
      }
      if (p.currentScreenSizes.screenWidth >= 1600) {
        console.log("check1600x900");
        setHeightValue1 = 5.9;
        setHeightValue2 = 3.4;
      }
      if (p.currentScreenSizes.screenWidth >= 1920) {
        console.log("check1920x1080");
        setHeightValue1 = 7.2;
        setHeightValue2 = 4.0;
      }
      if (p.currentScreenSizes.screenWidth >= 2560) {
        console.log("check2560");
        setHeightValue1 = 9.5;
        setHeightValue2 = 5.3;
      }

      var y = _.result(p, "y") || Math.ceil(Math.random() * p.itemWidth) + 1;

      if (
        p.contentData[i]?.post_type == "REELS" ||
        p.contentData[i]?.post_type == "IMAGE" ||
        p.contentData[i]?.post_type == "STORY" ||
        p.contentData[i]?.post_type == "VIDEO"
      ) {
        itemHeight = p.itemWidth * setHeightValue1;
      } else if (p.contentData[i]?.post_type == "FEED") {
        if (p.contentData[i].media_type == "VIDEO") {
          itemHeight = p.itemWidth * setHeightValue1;
        } else {
          itemHeight = p.itemWidth * setHeightValue2;
        }
      }

      return {
        x: (i * p.itemWidth) % 12,
        y: Math.floor(i / p.itemWidth) * y,
        w: p.itemWidth,
        h: itemHeight,
        i: i.toString(),
      };
    });
  }

  onLayoutChange(layout) {
    this.props.onLayoutChange(layout);
  }

  checkAndSetLargeCardHeight(p) {}

  render() {
    return (
      <ReactGridLayout
        layout={this.state.layout}
        onLayoutChange={this.onLayoutChange}
        {...this.props}
      >
        {this.state.contentData.length > 0 &&
          this.generateDOM(
            this.state.contentData,
            this.state.itemWidth,
            this.state.downloadMediaAPI,
            this.handlePauseState,
            this.handlePlayState,
            this.handleOnReadyState,
            this.replaceImage
          )}
      </ReactGridLayout>
    );
  }
}

// if (process.env.STATIC_EXAMPLES === true) {
//   import("../test-hook.jsx").then((fn) => fn.default(NoDraggingLayout));
// }
